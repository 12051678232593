<!-- eslint-disable max-len -->
<template>
  <div>

    <v-app class="app-background">


      <v-app-bar
        app
        :color="navClass"
        class="appbar"
        dark
        flat
        v-if="isLoggedIn"
        :clipped-left="true"
      >

        <v-app-bar-nav-icon
          @click="drawer = !drawer"
          aria-label="Menu"
        >
        </v-app-bar-nav-icon>
        <v-toolbar-title>{{pageTitle}}</v-toolbar-title>

        <v-spacer></v-spacer>
        <SynchroniseButton></SynchroniseButton>
        <MenuProfilButton
          v-if="this.me"
          :user="this.me"
          @log-out="logout()"
          @show-user-settings="showBottomSheetUserSettings()"
        ></MenuProfilButton>


        <v-progress-linear
          :active="loading"
          indeterminate
          absolute
          bottom
          color="white"
        ></v-progress-linear>

      </v-app-bar>
      <v-navigation-drawer
        :clipped="true"
        app
        permanent
        width="70"
        v-if="drawer && isLoggedIn"
        hide-overlay
        color="secondary"
        dark
        class="navigation-drawer"
      >
        <v-list>
          <v-list-item-group
          color="white"
          >
            <v-list-item
              v-for="itemMenu in this.menu"
              :key="itemMenu.title"
              :to="itemMenu.route"
              link
              role="option"
              @click="itemMenu.action && itemMenu.action()"
            >
              <v-list-item-icon class="listmenu">
                <v-icon>{{itemMenu.icon}}</v-icon>
                <span class="menu-title">{{ itemMenu.title }}</span>
              </v-list-item-icon>
              <!--<v-list-item-content>
                <v-list-item-title> {{ itemMenu.title }} </v-list-item-title>
              </v-list-item-content>-->
            </v-list-item>
            <v-list-item @click="goToTicket()">
              <v-list-item-icon class="listmenubottom">
                <v-icon>mdi-help-circle</v-icon>
                <span class="menu-title">Support</span>
              </v-list-item-icon>
            </v-list-item>
          </v-list-item-group>
        </v-list>

      </v-navigation-drawer>

      <v-content class="content">
        <BottomSheetUserSettings
          v-if="this.me"
          :sheet ="showUserSettings"
          :user ="this.me"
          @close-sheet = "showUserSettings = false">
        </BottomSheetUserSettings>
        <v-breadcrumbs class="breadcrumbs" :items="breadcrumList">
        </v-breadcrumbs>
        <router-view class="router"></router-view>
        <BaseSnackBar></BaseSnackBar>
        <v-row justify="center">
          <v-dialog
            v-model="updateExists"
            persistent
          >
          <v-card>
            <v-card-title class="card-titre" >
              Une nouvelle version est desormais disponible!

            </v-card-title>
            <v-card-text >
              <v-row>
                <!-- Nouvelles fonctionnalités -->
                <v-col class="fonctionnalite">
                  <div v-for="(news, index) in this.nouveaute"
                    :key="'new' + index">
                    <p><strong><span class='gras'>{{ getDisplayDate(news.dateMep) }} - {{ news.versionMep}}</span></strong></p>
                    <div v-html="news.contenuHtml"></div>
                    <br>
                    <v-divider></v-divider>
                    <br>
                  </div>
                  <br>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col cols="6" >
                  <p class="gras" >Afin de profiter de ces nouvelles
                     fonctionnalités veuillez suivre les étapes suivantes: </p>
                  <p style="color: black"> Nous vous rappelons que pour une <strong>
                    expérience optimale</strong>
                     de SMARTDIAG, il est préférable d'utiliser le <strong>navigateur
                     chrome.</strong></p>
                  <p style="color: red"> <v-icon style="color:red;font-size:30px;">
                    mdi-alert-outline</v-icon>Attention cette manipulation entrainera
                     la <strong>perte de vos données non synchronisées</strong>,
                      veillez à faire le nécessaire avant de procéder à cette manipulation.</p>

                  <img v-if="browserName === 'chrome' || browserName === 'autre'"
                  src="../public/img/chrome_cache.gif" class="image-cache">
                  <img v-if="browserName === 'firefox'"
                  src="../public/img/firefox_cache.gif" class="image-cache">
                  <p v-if="browserName === 'edge'"> Faites ctrl + maj + suppr <br>
                    Sélectionnez la période jusqu'à la dernière suppression de cache <br>
                    Cochez "Images et fichiers mis en cache"<br>
                    puis "Éffacer maintenant"<br>
                    <span style="color: red">
                      Cette manipulation supprimera tout le cache du navigateur.
                      Pour éviter celà, utilisez Chrome
                    </span> </p>

                </v-col>
              </v-row>
            </v-card-text>
            <v-btn class="close-button" color="primary" variant="text"
              @click="refreshApp">Fermer</v-btn>
          </v-card>
          </v-dialog>
        </v-row>
      </v-content>

    </v-app>
  </div>
</template>

<script>
/* eslint-disable no-console */

import { mapGetters, mapState } from 'vuex';

import axios from 'axios';
import axiosInstance from './plugins/axios';

import BaseSnackBar from './components/BaseSnackBar.vue';
import SynchroniseButton from './components/SynchroniseButton.vue';
import MenuProfilButton from './components/MenuProfilButton.vue';
import BottomSheetUserSettings from './components/BottomSheetUserSettings.vue';

export default {
  data() {
    return {
      menu: [
        { title: 'Accueil', icon: 'mdi-home-city', route: '/' },
        { title: 'Clients', icon: 'mdi-account-multiple', route: '/clients' },
        { title: 'Missions', icon: 'mdi-clipboard-text', route: '/missions' },
        { title: 'Paramètres', icon: 'mdi-cog', route: '/settings' },
        // eslint-disable-next-line no-return-assign
        { title: 'Nouveautés', icon: 'mdi-newspaper', action: () => (this.updateAvailable({ once: true })) },
      ],
      activeMenu: '',
      navClass: '#2D4388',
      refreshing: false,
      registration: null,
      updateExists: false,
      showUserSettings: false,
      browserName: 'autre',
      nouveaute: [],
    };
  },
  async mounted() {
    window.addEventListener('beforeunload', this.beforeWindowUnload);
  },
  async created() {
    if (this.isLoggedIn) {
      this.$store.dispatch('me/getMe');
    }
    // chargement nouveauté:
    axiosInstance.axiosInstance.get('/api/nouveautes/getAll')
      .then((response) => {
        this.nouveaute = response.data;
      });
    document.addEventListener('swUpdated', this.updateAvailable, { once: true });
    // Prevent multiple refreshes
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return;
        this.refreshing = true;
        // Here the actual reload of the page occurs
        window.location.reload();
      });
    }

    // Interceptor to handle error message from backend
    axios.interceptors.response.use(
      response => response,
      (error) => {
        console.log('error interceptor');
        // if it's an error 400, then display the error in the snackbar
        console.log('test', error.response.data.message);
        console.log('error : ', error.response);
        console.log('error status : ', error.response.status);
        // logout
        if (error.response
          && error.response.status >= 400
          && error.response.status !== 403
          && error.response.status !== 401
          && error.response.status < 500) {
          this.$store.dispatch('snackbar/displaySnack', { message: error.response.data.message, type: 'error' });
        }
        if (error.response
        && error.response.status === 403) {
          this.$store.dispatch('snackbar/displaySnack',
            {
              message: 'Vous n\'avez pas les droits pour cette action',
              type: 'error',
            });
        }
        if (error.response
        && error.response.status === 401) {
          this.$store.dispatch('snackbar/displaySnack',
            {
              message: 'Vous devez vous connectez',
              type: 'error',
            });

          this.logout();
        }
        return Promise.reject(error);
      },
    );

    /* eslint no-console: "off" */
    console.log('ENV : ', process.env.NODE_ENV);

    // Load all offline content stored in pouchDB
    this.$store.dispatch('loadOffline');

    // retrieve the app version number from the json file in public folder
    axios.get('/version.json').then((response) => {
      const { appVersion } = response.data;
      console.log('======================');
      console.log('version : ', appVersion);
      console.log('======================');
    });

    const environnement = window.location.hostname;
    if (environnement.includes('dev')) {
      this.navClass = '#2D4388';
    }
    if (environnement.includes('rec')) {
      this.navClass = '#d8345f';
    }
    if (environnement.includes('preprod')) {
      this.navClass = '#75b79e';
    }
  },
  components: {
    BaseSnackBar,
    SynchroniseButton,
    MenuProfilButton,
    BottomSheetUserSettings,
  },
  computed: {
    ...mapState({
      loading: 'loading',
      selectedView: 'selectedView',
      isLoggedIn: 'isLoggedIn',
    }),
    ...mapGetters({
      client: 'clients/selected',
      mission: 'missions/selected',
      site: 'sites/selected',
      batiment: 'batiments/selected',
      patrimoine: 'patrimoines/selected',
      me: 'me/me',
    }),
    drawer: {
      get() {
        return this.$store.state.navigationDrawer;
      },
      set(value) {
        this.$store.dispatch('updateNavigationDrawer', value);
      },
    },

    atHome() {
      return this.$route.name === 'home';
    },

    pageTitle() {
      const routeLibelle = this.$route.meta.libelle;

      if (routeLibelle && routeLibelle.startsWith('selected')) {
        switch (routeLibelle) {
          case 'selectedClient':
            return this.client ? this.client.nom : 'CLIENT';
          case 'selectedMission':
            return this.mission ? this.mission.nom : 'MISSION';
          case 'selectedSite':
            return this.site ? this.site.nom : 'SITE';
          case 'selectedBatiment':
            return this.batiment ? this.batiment.nom : 'BATIMENT';
          case 'selectedPatrimoine':
            return this.patrimoine ? this.patrimoine.nom : 'PATRIMOINE';
          default:
            return routeLibelle;
        }
      }

      return routeLibelle;
    },

    breadcrumList() {
      const list = [];
      if (this.client) {
        list.push({
          tooltip: this.client.nom,
          text: 'Client',
          to: { name: 'clientDetail', params: { clientId: this.client.id } },
          exact: true,
        });
      }

      if (this.mission) {
        list.push({
          tooltip: this.mission.nom,
          text: 'Mission',
          to: { name: 'missionDetail', params: { missionId: this.mission.id } },
          exact: true,
        });
      }

      if (this.patrimoine && !this.mission) {
        list.push({
          tooltip: this.patrimoine.nom,
          text: 'Patrimoine',
          to: { name: 'patrimoineDetail', params: { patrimoineId: this.patrimoine.id } },
          exact: true,
        });
      }

      if (this.site) {
        list.push({
          tooltip: this.site.nom,
          text: 'Site',
          to: { name: 'site', params: { missionId: this.mission.id, siteId: this.site.id } },
          exact: true,
        });
      }

      if (this.batiment) {
        list.push({
          tooltip: this.batiment.nom,
          text: 'Bâtiment',
          to: { name: 'batiment', params: { batimentId: this.batiment.id } },
          exact: true,
        });
      }

      if (this.selectedView) {
        list.push({
          tooltip: this.selectedView,
          text: this.selectedView,
          to: { name: '', params: '' },
          exact: true,
        });
      }
      return list;
    },
  },
  methods: {
    getDisplayDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day.substr(0, 2)}/${month.padStart(2, '0')}/${year.padStart(2, '0')}`;
    },
    goToTicket() {
      window.open('https://support.altyn-groupe.com/marketplace/userportal/front/portal.php?', '_blank');
    },
    showBottomSheetUserSettings() {
      this.showUserSettings = true;
    },
    // ...mapActions(['LogOut']),
    async logout() {
      axiosInstance.logout();
      // await this.LogOut();
      this.$store.dispatch('me/clearMe');
      this.$store.dispatch('changeIsLoggedIn', false);
      this.$router.push('/login');
    },

    updateAvailable(event) {
      this.detectBrowser();
      this.registration = event.detail;
      this.updateExists = true;
    },
    beforeWindowUnload(e) {
      if (!navigator.onLine) {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = '';
      }
    },
    refreshApp() {
      this.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    },
    detectBrowser() {
      const { userAgent } = navigator;
      if (userAgent.match(/chrome|chromium|crios/i)) {
        this.browserName = 'chrome';
        if (userAgent.match(/edg/i)) {
          this.browserName = 'edge';
        }
      } else if (userAgent.match(/firefox|fxios/i)) {
        this.browserName = 'firefox';
      } else {
        this.browserName = 'autre';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.listmenu{
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.listmenubottom{
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
  .app-background {
    background-color: $app-grey !important;
  }
.navigation-drawer {
  z-index: 12;
  box-shadow: 1px 1px 1px grey;
}

.logo {
  width: 128px;
  margin: 16px auto;
  display: block;
}

.content .breadcrumbs{
  background-color: #f5f6fa;
  width: 100%;
  z-index: 2;
  position: fixed;
  padding-bottom: 0;
}
.content .router{
  padding-top: 50px;
}

  // .content {
  //   max-height: 100vh;
  //   height: 100vh;
  // }

p{
  text-align: center;
}

.image-cache {
width: 50%;
margin-left: 25%;
margin-right: 25%;
}

.image-nouveaute {
width: 100%;
margin-left: 0%;
margin-right: 0%;
}
@media only screen and (max-width: 800px) {
  .image-cache {
    width: 82%;
    margin-left: 9%;
    margin-right: 9%;  }
}

.close-button {
  bottom: 10px;
  right: 10px;
  position: absolute;
}

.voir-plus-button {
  bottom: 10px;
  right: 10px;
}

.fonctionnalite{
height: 600px;
overflow-y: scroll;
}
.menu-title{
  font-size: 10px;
}

.card-titre{
  color:white;
  justify-content:center;
  background-color:#2D4388;
  margin-bottom: 15px;
}

.gras{
  font-weight: 700;
  font-size: large;
  color: black;
}
</style>
