<template>
  <div class="card">
    <v-icon
      class="iconExpend"
      color="primary"
      @click="showForm = !showForm"
      large
      >
      {{ showForm ? `mdi-minus-circle-outline`:`mdi-plus-circle-outline` }}
    </v-icon>
    <ValidationObserver ref="observer">
      <TitleWithInformations
        title="Attributs de coûts"
        :precision="form.fields.precisionChiffrage"
        :showParentInformation="showForm"
      >
      </TitleWithInformations>
      <div class="form-wrapper">
        <v-form v-if="showForm">
          <!-- RATIO DE CHIFFRAGE -->
          <v-card
            v-if="!creation"
            elevation="1"
            class="mb-8">
            <v-text-field
              v-bind="fieldOptions"
              label="Ratio de Chiffrage"
              type='number'
              suffix='€HT'
              v-model.number="form.fields.investissementUnitaireHt"
              @change="handleNumberNull('investissementUnitaireHt', $event)"
              :disabled="true"
            >
            </v-text-field>
            <div class="radio-field-wrapper mt-n12 mb-n4 pa-1 fields-switch-label">
              <slot name="field-label">Définir un ratio de chiffrage préféré : </slot>
              <v-switch
                v-model="form.fields.hasRatioDeChiffragePrefere"
                @change="reInitRatioDeChiffrageField()"
              ></v-switch>
            </div>
            <ValidationProvider
              v-slot="{ errors }"
              name="ratioDeChiffragePrefere"
              ref="ratioDeChiffragePrefere"
            >
              <div v-if="form.fields.hasRatioDeChiffragePrefere">
                <v-text-field
                  v-bind="fieldOptions"
                  label="Ratio de chiffrage préféré"
                  type="number"
                  class="mt-n4 pa-1 mb-n2"
                  v-model.number="form.fields.ratioDeChiffragePrefere"
                  :error-messages="errors"
                  @change="handleNumberNull('ratioDeChiffragePrefere', $event)"
                >
                </v-text-field>
              </div>
            </ValidationProvider>
          </v-card>

          <!-- QUANTITATIF CHIFFRAGE -->
          <v-card
            elevation="1"
            class="mb-8">
            <v-text-field
              v-bind="fieldOptions"
              label="Quantitatif Chiffrage"
              type='number'
              :suffix='uniteQuantitatifChiffrage'
              v-model.number="quantitatifChiffrageComputed"
              :disabled="true"
            >
            </v-text-field>
            <div class="radio-field-wrapper mt-n12 mb-n4 pa-1 fields-switch-label">
              <slot name="field-label">Définir un quantitatif chiffrage préféré : </slot>
              <v-switch
                v-model="form.fields.hasQuantitatifChiffragePrefere"
                @change="reInitQuantitatifChiffrageField()"
              ></v-switch>
            </div>
            <ValidationProvider
              v-slot="{ errors }"
              name="quantitatifChiffragePrefere"
              ref="quantitatifChiffragePrefere"
            >
              <div v-if="form.fields.hasQuantitatifChiffragePrefere">
                <v-text-field
                  v-bind="fieldOptions"
                  label="Quantitatif chiffrage préférée"
                  type="number"
                  class="mt-n4 pa-1 mb-n2"
                  v-model.number="form.fields.quantitatifChiffragePrefere"
                  :error-messages="errors"
                  @change="handleNumberNull('quantitatifChiffragePrefere', $event)"
                >
                </v-text-field>
              </div>
            </ValidationProvider>
          </v-card>

          <!-- COEFF PONDERATION COUT -->
          <ValidationProvider
            v-slot="{ errors }"
            name="coeffPonderation"
            ref="coeffponderationcout"
          >
            <v-text-field
              v-bind="fieldOptions"
              label="Coefficient de pondération des coûts"
              type='number'
              v-model.number="form.fields.coefPonderationCoutChiffrage"
              :error-messages="errors"
              @change="
              handleNumberNull('coefPonderationCoutChiffrage',
              $event)"
            >
            </v-text-field>
          </ValidationProvider>

          <!-- INVESTISSEMENT -->
          <v-card
            v-if="!creation"
            elevation="1"
            class="mb-8">
            <v-text-field
              v-bind="fieldOptions"
              label="Investissement"
              type='number'
              class='pa-1 invest-field'
              :disabled="true"
              v-model.number="this.featuredInter.calculations.investissementHtIntermediaire"
              persistent-hint
              hint="L'investissement intègre le coefficient de
              pondération défini au niveau de la mission."
            >
            </v-text-field>

            <div class="radio-field-wrapper mt-n12 mb-n4 pa-1 fields-switch-label">
              <slot name="field-label">Définir un coût forfaitaire préférée : </slot>
              <v-switch
                v-model="form.fields.hasCoutForfaitairePreferee"
                @change="reInitCoutForfaitaireField()"
              ></v-switch>
            </div>
            <ValidationProvider
              v-slot="{ errors }"
              name="coutForfaitaire"
              rules="minEqVal:0"
            >
              <div v-if="form.fields.hasCoutForfaitairePreferee">
                <v-text-field
                  v-bind="fieldOptions"
                  label="Valeur de coût forfaitaire préférée"
                  type="number"
                  class="mt-n4 pa-1 mb-n2"
                  v-model.number="form.fields.coutForfaitairePreferee"
                  :error-messages="errors"
                  @change="handleNumberNull('coutForfaitairePreferee', $event)"
                >
                </v-text-field>
              </div>
            </ValidationProvider>
          </v-card>
        </v-form>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapGetters, mapState } from 'vuex';
import TitleWithInformations from '../fields/TitleWithInformations-field.vue';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    TitleWithInformations,
  },

  props: ['batiment', 'intervention', 'creation'],

  data() {
    return {
      variableChiffrage: null,
      quantitatifChiffrage: null,
      showForm: true,
      form: {
        valid: true,
        touched: true,
        fields: {
          investissementUnitaireHt: null,
          quantitatifChiffragePrefere: null,
          coefPonderationCoutChiffrage: null,
        },
      },
      fieldOptions: {
        outlined: true,
        dense: true,
      },
    };
  },

  computed: {
    ...mapState('interventions', ['interventions']),
    ...mapState('listeOptions', { listesOptions: 'all' }),
    ...mapState('equipements', ['equipements']),
    ...mapGetters({
      mission: 'missions/selected',
      site: 'sites/selected',
    }),

    featuredInter() {
      if (this.interventions) {
        return this.interventions.find(i => i.id === this.intervention.id);
      }
      return this.intervention;
    },
    equipementInter() {
      return this.equipements.find(e => e.id === this.intervention.equipement);
    },

    interventionType() {
      if (this.listesOptions) {
        return this.listesOptions.find(opt => opt.codeOption === this.intervention.option);
      }
      return null;
    },

    uniteQuantitatifChiffrage() {
      if (this.intervention && this.intervention.referentielValues) {
        return this.intervention.referentielValues.option_uniteChiffrage;
      }
      if (this.interventionType && this.interventionType.data) {
        return this.interventionType.data.uniteChiffrage;
      }
      return '';
    },
    quantitatifChiffrageComputed() {
      if (this.quantitatifChiffrage === null) {
        this.getQuantitatifChiffrage();
        return null;
      }
      return this.quantitatifChiffrage;
    },

  },


  async created() {
    await this.$store.dispatch('listeOptions/fetchAllByType',
      {
        missionId: this.mission.id,
        codeType: 'ref_TypeUsage',
      });
    if (!this.interventionType) {
      await this.$store.dispatch('listeOptions/fetchByCodeOption', {
        missionId: this.mission.id,
        codeOption: this.intervention.option,
      });
    }
    if (this.creation) {
      // init fields with referential if creation
      this.initFormValues();
    } else if (this.intervention && this.intervention.referentielValues) {
      this.form.fields = {
        // no intervention field for ratio Chiffrage
        investissementUnitaireHt: this.intervention.calculations.investissementUnitaireHt,
        ratioDeChiffragePrefere: this.intervention.ratioDeChiffragePrefere,
        hasRatioDeChiffragePrefere: this.intervention.hasRatioDeChiffragePrefere,
        quantitatifChiffragePrefere: this.intervention.quantitatifChiffragePrefere,
        hasQuantitatifChiffragePrefere: this.intervention.hasQuantitatifChiffragePrefere,
        coefPonderationCoutChiffrage: this.intervention.coefPonderationCoutChiffrage,
        coutForfaitairePreferee: this.intervention.coutForfaitairePreferee,
        hasCoutForfaitairePreferee: this.intervention.hasCoutForfaitairePreferee,
        precisionChiffrage: this.intervention.referentielValues.option_precisionChiffrage,
      };
      if (this.interventionType) {
        const { variableChiffrage } = this.interventionType.data;
        const refreshedValue = this.getQuantitatifChiffrage(variableChiffrage);

        this.form.fields.referentielQuantitatifChiffrage = refreshedValue;
      }
    }
  },

  mounted() {
    if (this.creation) {
      const coeffPonderCoutValid = this.$refs.coeffponderationcout.validate();
      // const quantiChiffValid = this.$refs.quantitatifchiffrage.validate();
      // this.form.valid = this.$refs.observer.validate();
      if (coeffPonderCoutValid) {
        this.form.valid = true;
      } else {
        this.form.valid = false;
      }
      this.$emit('form-updated', this.form);
    }
  },
  methods: {
    handleNumberNull(nameField, newValue) {
      if (!newValue) {
        this.form.fields[nameField] = null;
      }

      this.handleFormUpdate();
    },

    async handleFormUpdate() {
      if (!this.form.touched) {
        this.form.touched = true;
      }
      if (this.$refs.observer) {
        this.form.valid = await this.$refs.observer.validate({ silent: true });
      }
      this.$emit('form-updated', this.form);
    },

    reInitCoutForfaitaireField() {
      if (!this.form.fields.hasCoutForfaitairePreferee) {
        this.form.fields.hasCoutForfaitairePreferee = false;
      }
      this.handleFormUpdate();
    },

    reInitQuantitatifChiffrageField() {
      if (!this.form.fields.hasQuantitatifChiffragePrefere) {
        this.form.fields.hasQuantitatifChiffragePrefere = false;
      }
      this.handleFormUpdate();
    },

    reInitRatioDeChiffrageField() {
      if (!this.form.fields.hasRatioDeChiffragePrefere) {
        this.form.fields.hasRatioDeChiffragePrefere = false;
      }
      this.handleFormUpdate();
    },


    initFormValues() {
      if (this.interventionType) {
        const {
          variableChiffrage,
          precisionChiffrage,
        } = this.interventionType.data;
        this.form.fields = {
          investissementUnitaireHt: null,
          referentielQuantitatifChiffrage: this.getQuantitatifChiffrage(variableChiffrage),
          coefPonderationCoutChiffrage: 1,
          hasCoutForfaitairePreferee: false,
          precisionChiffrage,
        };
      }
      this.$emit('form-updated', this.form);
    },

    async getQuantitatifChiffrage(variableChiffrage) {
      if (this.equipementInter && typeof variableChiffrage === 'string') {
        const splittedVariable = variableChiffrage.split('.');
        if (splittedVariable.length === 2) {
          const [firstKey, secondKey] = splittedVariable;
          const quantitatifChiffrage = await this.equipementInter[firstKey][secondKey];
          this.quantitatifChiffrage = parseFloat(quantitatifChiffrage);
        }
      }
      return null;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-messages__message {
  color: red;
}
.invest-field {
  margin-bottom: 1em;
}

.radio-field-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.fields-switch-label {
  font-size: 0.8rem;
}
.form-wrapper {
  width: 100%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: stretch;
}

.iconExpend {
  float: right;
}
.card {
  width: 100%;
}
.formCard {
  display:flex;
  justify-content: center;
  flex-flow: column nowrap;
  align-items: center;
}
</style>
