<template>
  <v-bottom-sheet
    v-model="sheet"
    fullscreen
    persistent
  >
    <v-sheet class="sheet-wrapper">

      <ValidationObserver
        v-if="!showBottomSheetNewRcus"
        tag="div"
        class="observer-wrapper"
        ref="obs"
      >
        <h2 class="fixed-title">{{title}}</h2>
        <div class="blocks">
          <v-expansion-panels
            class="expansion-panels"
            v-model="panel"
            multiple
            popout
          >
            <v-expansion-panel>
              <v-expansion-panel-header expand>
                <div class="title">
                  <p class="fields-group-title"> Qualifier l'état initial </p>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p class="qualif-info-un">Synthèse des consommations lissées</p>
                <div>
                  <!-- tableau année de Référence -->
                  <v-data-table
                    v-model="selectedAnnees"
                    :headers="headers"
                    :items="annees"
                    show-select
                    hide-default-footer
                    item-key="annee"
                    no-data-text="Aucune facture n'a été trouvée pour ce compteur"
                    disable-pagination
                  >
                    <template v-slot:[`item.alert`]="{ item }">
                      <v-icon class="alert-icon">{{item.alert}}</v-icon>
                    </template>
                  </v-data-table>
                  <div class="info-alerte">
                    <v-icon class="alert-icon">
                      mdi-alert-circle
                    </v-icon>
                    <span class="qualif-info-deux">
                      Les alertes indiquent qu'une année est incomplète
                    </span>
                  </div>

                  <!-- inputs consoMoyenne & depenseMoyenne -->
                  <div class="fields-wrapper">
                    <NumberField
                      :commonOptions="consoOptions"
                      :defaultValue="consoMoy"
                      :formField="consoMoyField"
                      @change="handleConsoChange($event)"
                    >
                      <template v-slot:field-label>
                        <div>{{ consoMoyField.label }}</div>
                      </template>
                    </NumberField>

                    <NumberField
                      :commonOptions="depenseOptions"
                      :defaultValue="depenseMoy"
                      :formField="depenseMoyField"
                      @change="handleDepenseChange($event)"
                    >
                      <template v-slot:field-label>
                        <div>{{ depenseMoyField.label }}</div>
                      </template>
                    </NumberField>
                  </div>
                </div>
                <div class="rcu" v-if="compteur && compteur.energie==='reseaudechaleur-energie'">
                  <h2 >Choix du RCU</h2>
                  <p>Selectionner un nouveau RCU pour ce compteur:</p>
                  <v-autocomplete
                    v-model="selectedRcu"
                    :filter="filterObject"
                    class="search-field"
                    clearable
                    return-object
                    :items="options"
                    item-value="id"
                    item-text="libelle"
                    label="Rcu"
                    placeholder="Saisissez du texte pour rechercher un RCU"
                    :search-input.sync="searchInput"
                    @change="changeRcu()"
                  >
                    <template slot="item" slot-scope="data">
                      <v-list-item-group>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title v-html="data.item.libelle"></v-list-item-title>
                            <v-list-item-title v-html="`département:
                            ${data.item.data.departement}`">
                            </v-list-item-title>
                            <v-list-item-title v-html="`coeff.: ${data.item.data.co2}`">
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </template>
                  </v-autocomplete>
                  <div class="fields-wrapper">
                    <div>Nom du RCU:
                      <DisplayField
                      class="displayRcu"
                      :commonOptions="rcuOptions"
                      :defaultValue="nameRcu"
                      :formField="nameRcuField"
                      >
                    </DisplayField>
                    </div>
                    <div>Coefficient Co2 du RCU:
                      <DisplayField
                      class="displayRcu"
                      :commonOptions="rcuOptions"
                      :defaultValue="coeffRcu"
                      :formField="coeffRcuField"
                      >
                      </DisplayField>
                    </div>
                  </div>
                  <div class="fields-wrapper">
                    Attention si des valeurs préférées sont saisies,
                    elles seront prises en compte à la place d'un potentiel
                    RCU selectionné ci-dessus.
                    <div>
                      <TextField
                      class="displayRcu"
                      :commonOptions="rcuPrefereOptions"
                      :defaultValue="rcuPrefere.name"
                      :formField="nameRcuFieldPrefere"
                      @change="changeRcuPrefere('name', $event)"
                      >
                      <template v-slot:field-label>
                        <div>{{ nameRcuFieldPrefere.label }}</div>
                      </template>
                    </TextField>
                    </div>
                    <div>
                      <NumberField
                      class="displayRcu"
                      :commonOptions="rcuPrefereOptions"
                      :defaultValue="rcuPrefere.co2"
                      :formField="coeffRcuFieldPrefere"
                      @change="changeRcuPrefere('co2', $event)"
                      >
                      <template v-slot:field-label>
                        <div>{{ coeffRcuFieldPrefere.label }}</div>
                      </template>
                      </NumberField>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="title">
                    <p class="fields-group-title"> Gérer les factures </p>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div  class="attr-gen-wrapper">
                    <v-data-table
                      :headers="headersFactures"
                      :items="factures"
                      hide-default-footer
                      item-key="id"
                      no-data-text="Aucune facture n'a été trouvée pour ce compteur"
                      disable-pagination
                      dense
                      multi-sort
                      show-select
                      v-model="selectedFactures"
                    >
                      <template v-slot:top>
                        <p class="facture-message-alerte">
                          Attention, une facture renseignée sur cette page
                          peut être écrasée si une facture avec même numéro de facture,
                          date de début et date de fin, est importée avec la fonction
                          “IMPORTER FACTURE” qui est disponible au niveau du client.
                        </p>
                        <v-toolbar
                          flat
                        >
                          <v-dialog
                            v-model="addFacture"
                            max-width="500px"
                          >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  Nouvelle facture
                                </v-btn>
                                <v-btn
                                  color="error"
                                  dark
                                  depressed
                                  class="mb-2"
                                  :disabled="isSelected"
                                  @click="showDeleteDialog()"
                                >Supprimer</v-btn>
                            </template>
                          </v-dialog>
                          <DialogDeleteConfirmation
                            :isFacture="true"
                            :dialog="deleteDialog"
                            @delete-item="supprimerFacture()"
                            @close-dialog="closeDeleteDialog()"
                          >
                          </DialogDeleteConfirmation>
                        </v-toolbar>
                      </template>
                      <template v-slot:[`body.prepend`]>
                        <tr>
                          <td></td>
                          <td>
                            <v-text-field
                              v-model="searchTableFacture.searchNumFacture"
                              type="text"
                              label="Search.."
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="searchTableFacture.searchDateDebut"
                              type="text"
                              label="Search.."
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="searchTableFacture.searchDateFin"
                              type="text"
                              label="Search.."
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="searchTableFacture.searchConsoEf"
                              type="number"
                              label="Search.."
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="searchTableFacture.searchCoutTotal"
                              type="number"
                              label="Search.."
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="searchTableFacture.searchCoutAbo"
                              type="number"
                              label="Search.."
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="searchTableFacture.searchNumFactureReference"
                              type="text"
                              label="Search.."
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="searchTableFacture.searchSourceDonnee"
                              type="text"
                              label="Search.."
                            ></v-text-field>
                          </td>
                        </tr>
                      </template>
                      <template v-slot:[`item.consommationEf`]="{ item }">
                          <span>{{ parseFloat(item.consommationEf).toLocaleString('fr') }}</span>
                      </template>
                      <template v-slot:[`item.coutTotal`]="{ item }">
                          <span>{{ parseFloat(item.coutTotal).toLocaleString('fr') }}</span>
                      </template>
                      <template v-slot:[`item.coutAbonnement`]="{ item }">
                          <span>{{ parseFloat(item.coutAbonnement).toLocaleString('fr') }}</span>
                      </template>
                      <template v-slot:[`item.modify`]="{ item }">
                          <v-icon
                            color="primary"
                            small
                            @click="modifier(item)"
                          >
                            mdi-circle-edit-outline
                          </v-icon>
                      </template>
                    </v-data-table>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
          </v-expansion-panels>


      </div>
        <!-- close and save buttons -->
        <div class="bottom-sheet-footer">
          <v-btn
            class="action-button"
            text
            color="primary"
            @click="closeSheet()"
          >
            Annuler
          </v-btn>

          <v-btn
            class="action-button"
            depressed
            large
            color="primary"
            @click.native="saveForm()"
            :disabled="qualifyOk"
            :loading="saving"
          >
            Qualifier
          </v-btn>
        </div>
      </ValidationObserver>
    </v-sheet>
    <BottomSheetAddItem
      :sheet="addFacture"
      :parentItem="compteur"
      :factures="factures"
      typeItem="factures"
      @close-sheet="majBeforeClose()"
    >
    </BottomSheetAddItem>
    <BottomSheetEditItem
      :sheet="showEditSheet"
      :item="factureToModify"
      typeItem="factures"
      :factures="factures"
      @close-sheet="handleCloseEdit()"
    >
    </BottomSheetEditItem>
  </v-bottom-sheet>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import { ValidationObserver } from 'vee-validate';

import axiosInstance from '../plugins/axios';

import NumberField from './fields/number-field.vue';
import DisplayField from './fields/display-field.vue';
import TextField from './fields/text-field.vue';
import BottomSheetAddItem from './BottomSheetAddItem.vue';
import BottomSheetEditItem from './BottomSheetEditItem.vue';
import DialogDeleteConfirmation from './DialogDeleteConfirmation.vue';


export default {
  components: {
    ValidationObserver,
    NumberField,
    DisplayField,
    BottomSheetAddItem,
    BottomSheetEditItem,
    DialogDeleteConfirmation,
    TextField,
  },

  props: {
    sheet: {
      type: Boolean,
    },
    compteur: {
      type: Object,
    },
  },

  data() {
    return {
      rcuPrefere: {
        name: null,
        co2: null,
      },
      compteurSelected: null,
      addFacture: false,
      panel: [0],
      showFormFacture: false,
      showFormReference: false,
      factures: [],
      selectedFactures: [],
      factureToDelete: [],
      factureToModify: null,
      showEditSheet: false,
      selectedRcu: null,
      deleteDialog: false,
      options: [],
      typeItem: 'rcus',
      onlyRequired: false,
      showBottomSheetNewRcus: false,
      saving: false,
      selectedAnnees: [],
      consoMoy: 0,
      depenseMoy: 0,
      coeffRcu: '',
      nameRcu: '',
      iri: '',
      searchInput: '',
      searchTableFacture: {
        searchNumFacture: '',
        searchDateDebut: '',
        searchDateFin: '',
        searchConsoEf: '',
        searchCoutTotal: '',
        searchCoutAbo: '',
        searchNumFactureReference: '',
        searchSourceDonnee: '',
      },
      headersFactures: [
        {
          text: 'Numéro facture',
          align: 'left',
          value: 'numerofacture',
          filter: (value) => {
            if (!this.searchTableFacture.searchNumFacture) return true;
            return value.includes(this.searchTableFacture.searchNumFacture);
          },
        },
        {
          text: 'Date de début',
          value: 'dateDebutToString',
          filter: (value) => {
            if (!this.searchTableFacture.searchDateDebut) return true;
            return value.includes(this.searchTableFacture.searchDateDebut);
          },
        },
        {
          text: 'Date de fin',
          value: 'dateFinToString',
          filter: (value) => {
            if (!this.searchTableFacture.searchDateFin) return true;
            return value.includes(this.searchTableFacture.searchDateFin);
          },
        },
        {
          text: 'Consommation (kWhEF PCI)',
          value: 'consommationEf',
          filter: (value) => {
            if (!this.searchTableFacture.searchConsoEf) return true;
            return value.includes(this.searchTableFacture.searchConsoEf);
          },
        },
        {
          text: 'Coût total (€TTC)',
          value: 'coutTotal',
          filter: (value) => {
            if (!this.searchTableFacture.searchCoutTotal) return true;
            return value.includes(this.searchTableFacture.searchCoutTotal);
          },
        },
        {
          text: 'Coût abonnement (€TTC)',
          value: 'coutAbonnement',
          filter: (value) => {
            if (!this.searchTableFacture.searchCoutAbo) return true;
            return value.includes(this.searchTableFacture.searchCoutAbo);
          },
        },
        {
          text: 'Numéro facture de référence',
          value: 'numerofactureDeReference',
          width: '220px',
          filter: (value) => {
            if (!this.searchTableFacture.searchNumFactureReference) return true;
            return value.includes(this.searchTableFacture.searchNumFactureReference);
          },
        },
        {
          text: 'Source de données',
          value: 'sourceDonnee',
          filter: (value) => {
            if (!this.searchTableFacture.searchSourceDonnee) return true;
            return value.includes(this.searchTableFacture.searchSourceDonnee);
          },
        },
        {
          value: 'modify',
        },
      ],
      headers: [
        {
          text: 'Année',
          align: 'left',
          sortable: false,
          value: 'annee',
        },
        { text: 'Consommation (kWhEF PCI)', value: 'displayConso' },
        { text: 'Dépenses (€TTC)', value: 'displayDepense' },
        { text: '', value: 'alert', width: '10px' },
      ],
      consoMoyField: {
        name: 'consoMoy',
        label: "Consommation de l'état initial",
        rules: 'required|minVal:0',
        placeholder: '',
        unit: 'kWh',
      },
      depenseMoyField: {
        name: 'depenseMoy',
        label: "Dépenses de l'état initial",
        rules: 'required|minVal:0',
        placeholder: '',
        unit: 'EUR',
      },
      nameRcuField: {
        name: 'nomRcu',
        label: 'Nom Rcu',
        type: 'text',
        placeholder: '',
      },
      coeffRcuField: {
        name: 'coeffRcu',
        label: 'Coeff Rcu',
        type: 'number',
        placeholder: '',
      },
      nameRcuFieldPrefere: {
        name: 'nomRcu',
        label: 'Nom Rcu préféré',
        type: 'text',
        placeholder: '',
      },
      coeffRcuFieldPrefere: {
        name: 'coeffRcu',
        label: 'Coeff Rcu préféré',
        type: 'number',
        placeholder: '',
      },
    };
  },

  computed: {
    ...mapState(['currentForm']),
    ...mapState(['factures']),
    ...mapGetters('missions', { selectedMission: 'selected' }),
    ...mapGetters('sites', { selectedSite: 'selected' }),

    title() {
      if (this.compteur) {
        return `Qualification du compteur ${this.compteur.numeroCompteur}`;
      }
      return 'Aucun compteur sélectionné';
    },

    // convert the facturesAnnees object of the compteur in an array of objects
    annees() {
      if (this.compteurSelected && this.compteurSelected.facturesAnnees) {
        return [...Object.keys(this.compteurSelected.facturesAnnees)].map(key => ({
          annee: key,
          consommation: this.compteurSelected.facturesAnnees[key].conso,
          displayConso: parseFloat(this.compteurSelected.facturesAnnees[key].conso).toLocaleString('fr'),
          depense: this.compteurSelected.facturesAnnees[key].cout,
          displayDepense: parseFloat(this.compteurSelected.facturesAnnees[key].cout).toLocaleString('fr'),
          alert: this.compteurSelected.facturesAnnees[key].fullYear ? '' : 'mdi-alert-circle',
        }));
      }
      return [];
    },

    qualifyOk() {
      if (this.consoMoy === 0 && this.depenseMoy === 0) {
        return true;
      }
      return false;
    },

    commonOptions() {
      return {
        outlined: true,
        readonly: !!this.selectedAnnees.length,
      };
    },

    rcuOptions() {
      return {
      };
    },
    rcuPrefereOptions() {
      return {
        outlined: true,
        dense: true,
        persistentHint: true,
        clearable: true,
      };
    },

    consoOptions() {
      return {
        ...this.commonOptions,
        suffix: 'kWh',
      };
    },

    depenseOptions() {
      return {
        ...this.commonOptions,
        suffix: 'EUR',
      };
    },

    isSelected() {
      if (this.selectedFactures.length > 0) {
        return false;
      } return true;
    },
  },

  watch: {
    compteur(newValue) {
      this.compteurSelected = newValue;
    },
    selectedAnnees(newValue, oldValue) {
      // update fields with average each time the selected annee list change
      if (newValue.length) {
        this.consoMoy = this.getConsoAvg(newValue);
        this.depenseMoy = this.getDepenseAvg(newValue);
      }
      // if transitioning from some annee selected to 0, then whipe the fields
      if (newValue.length < 1 && oldValue.length > 0) {
        this.consoMoy = 0;
        this.depenseMoy = 0;
      }
    },

    sheet(newValue, oldValue) {
      // each time the sheet is opened
      if (newValue && !oldValue) {
        axiosInstance.axiosInstance.get(`/api/compteurs/${this.compteur.id}/factures`).then((response) => {
          this.factures = response.data;
        });
        // set the default selected annees with the data allready on the server
        this.selectedAnnees = this.annees
          .filter(obj => this.compteur.anneesQualif.includes(obj.annee));
        // update conso and depense on the next tick
        // to avoid resetting them by mistake
        this.$nextTick(() => {
          if (this.compteur.consoQualif) {
            this.consoMoy = this.compteur.consoQualif;
          } else {
            this.consoMoy = 0;
          }

          if (this.compteur.coutQualif) {
            this.depenseMoy = this.compteur.coutQualif;
          } else {
            this.depenseMoy = 0;
          }
          if (this.compteur.iriRCU) {
            axiosInstance.axiosInstance.get(this.compteur.iriRCU).then((response) => {
              this.iri = response.data;
              this.nameRcu = this.iri.libelle;
              this.searchInput = this.iri.libelle;
              if (this.iri.data) {
                this.coeffRcu = this.iri.data.co2;
              }
            });
          } else {
            this.searchInput = '';
            this.iri = '';
            this.nameRcu = '';
          }
          if (this.compteur.rcuPrefere) {
            this.rcuPrefere = this.compteur.rcuPrefere;
          }

          if (this.compteur.coefCo2) {
            this.coeffRcu = this.compteur.coefCo2;
          } else {
            this.coeffRcu = null;
          }
        });
      }
    },
  },

  created() {
    this.getListeRcu();
  },

  methods: {
    changeRcuPrefere(element, value) {
      if (element === 'name') {
        this.rcuPrefere.name = value;
      } else if (element === 'co2') {
        this.rcuPrefere.co2 = value;
      }
    },
    async majBeforeClose() {
      await this.$store.dispatch('compteurs/refreshItem', this.compteur.id).then((response) => {
        this.compteurSelected = response;
      });
      axiosInstance.axiosInstance.get(`/api/compteurs/${this.compteur.id}/factures`).then((response) => {
        this.factures = response.data;
      });
      this.addFacture = false;
    },
    changeRcu() {
      if (this.selectedRcu) {
        this.nameRcu = this.selectedRcu.libelle;
        this.coeffRcu = parseFloat(this.selectedRcu.data.co2);
        this.searchInput = this.selectedRcu.libelle;
      } else {
        this.nameRcu = 'Réseau de chaleur';
        this.coeffRcu = 0.07;
        this.searchInput = '';
      }
    },
    getListeRcu() {
      axiosInstance.axiosInstance.get(`/api/ref/missions/${this.selectedMission.id}/liste-options?codeType=ref_rcu`).then((response) => {
        this.options = response.data;
      });
    },
    filterObject(item, queryText) {
      return (
        item.libelle.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      || item.data.departement.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },

    handleConsoChange(value) {
      this.consoMoy = value;
    },

    handleDepenseChange(value) {
      this.depenseMoy = value;
    },

    // get the average consommation of the selected annees rounded to 2nd decimal
    getConsoAvg(listAnnees) {
      return this.getAverage(listAnnees.map(annee => annee.consommation));
    },

    // get the average depense of the selected annees rounded to 2nd decimal
    getDepenseAvg(listAnnees) {
      return this.getAverage(listAnnees.map(annee => annee.depense));
    },

    // calculate the average of a list of numbers
    // rounded to the 2nd decimal
    getAverage(listVal) {
      const sum = listVal.reduce((acc, curr) => acc + curr, 0);
      const average = sum / listVal.length;
      // first calculate the round with exponential value to avoid errors
      const exponentialRound = Math.round(`${average}e2`);
      return Number(`${exponentialRound}e-2`);
    },


    saveForm() {
      this.saving = true;
      const compteurToSave = { ...this.compteur };
      // update the compteur
      compteurToSave.anneesQualif = this.selectedAnnees.map(obj => obj.annee);
      compteurToSave.consoQualif = this.consoMoy;
      compteurToSave.coutQualif = this.depenseMoy;
      compteurToSave.rcuPrefere = Object.assign({}, this.rcuPrefere);
      if (this.selectedRcu) {
        compteurToSave.iriRCU = this.selectedRcu.id;
      }
      if (compteurToSave.energie === 'reseaudechaleur-energie') {
        // this.compteur.iriRCU = this.selectedRcu.id;
        if (!this.selectedRcu && this.iri) {
          this.selectedRcu = this.iri;
        }

        if (!compteurToSave.iriRCU && this.selectedRcu && this.selectedRcu.data) {
          compteurToSave.iriRCU = this.selectedRcu[Object.keys(this.selectedRcu)[0]];
          compteurToSave.coefCo2 = parseFloat(this.selectedRcu.data.co2);
        }
        if (this.nameRcu === 'Réseau de chaleur' && this.coeffRcu) {
          this.searchInput = '';
          compteurToSave.iriRCU = null;
          compteurToSave.coefCo2 = null;
        }
      } else {
        compteurToSave.coefCo2 = null;
      }
      compteurToSave.inAlert = false;
      // save the compteur and close the sheet
      this.$store.dispatch('compteurs/save', compteurToSave)
        .then((responseCompteur) => {
          this.$store.dispatch('snackbar/displaySnack', { message: 'Compteur qualifié !', type: 'succes' });
          this.$emit('refresh-batiments');
          this.$emit('select-compteur', responseCompteur);
        })
        .finally(() => {
          this.$store.dispatch('sites/refreshItem', this.selectedSite.id);
          this.saving = false;
          this.closeSheet();
        });
    },

    modifier(item) {
      this.showEditSheet = true;
      this.factureToModify = item;
    },

    handleCloseEdit() {
      this.majBeforeClose();
      this.showEditSheet = false;
    },

    closeSheet() {
      this.$emit('close-sheet');
      this.panel = [0];
      this.searchTableFacture = {
        searchNumFacture: '',
        searchDateDebut: '',
        searchDateFin: '',
        searchConsoEf: '',
        searchCoutTotal: '',
        searchCoutAbo: '',
        searchNumFactureReference: '',
        searchSourceDonnee: '',
      };
    },
    async supprimerFacture() {
      let messageSuccess = '';
      let selectedFacturesIds = [];
      for (let i = 0; i < this.selectedFactures.length; i += 1) {
        selectedFacturesIds.push(this.selectedFactures[i].id);
      }
      let factureToDelete = {
        facture: selectedFacturesIds,
      };
      try {
        await this.$store.dispatch('compteurs/deleteFactures', { compteurId: this.compteur.id, facturesIds: factureToDelete })
          .then(() => {
            if (this.selectedFactures.length > 1) {
              messageSuccess = 'Les factures ont bien été supprimées';
            } else {
              messageSuccess = 'La facture a bien été supprimée';
            }
            this.$store.dispatch('snackbar/displaySnack',
              { message: messageSuccess, type: 'succes' });
          });
      } catch (error) {
        this.$store.dispatch('snackbar/displaySnack',
          { message: `Erreur lors de la suppression : ${error}`, type: 'error' });
      }
      this.selectedFactures = [];
      factureToDelete = {};
      selectedFacturesIds = [];
      this.majBeforeClose();
    },

    showDeleteDialog() {
      this.deleteDialog = true;
    },

    closeDeleteDialog() {
      this.deleteDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .expansion-panels{
    z-index:0 !important;
  }
  .alert-icon{
    color:orange !important;
    margin-right:3px;
  }
  .info-alerte{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: right;
  }
  .qualif-info-un{
    text-align: center;
  }
  .qualif-info-deux{
    text-align: right;
    margin-top:2px;
    font-size: 12px;
    font-style: italic;
  }

  .facture-message-alerte{
    text-align: center;
    color:red;
  }
  .rcu{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }
  .fixed-title{
    position: fixed;
    height: 64px;
    top: 0;
    margin-top: 0;
    width:100%;
    background-color: #2D4388;
    z-index: 10;
    color:white !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.2rem;
  }
  .blocks{
    width:100%;
 }
  .attr-gen-wrapper {
    width: 100%;
    border-radius: 5px;
    padding:1rem;
    margin: 1rem;
    background-color: white;
  }
  .sheet-wrapper {
    padding: 100px 3rem 100px 3rem;
    overflow: auto;
    height: 100%;
    width:100%;
    overscroll-behavior: none;
    background-color: #f9f9f9;
  }
  .observer-wrapper {
    // height: 95%;
    //width:100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    > * {
      margin-bottom: 3rem;
    }
  }

  .fields-wrapper {
    margin-top: 2rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    //width: 100%;
        > * {
      margin: 0.4rem;
    }
  }

  .bottom-sheet-footer {
    width: 100%;
    height: 64px;
    bottom: 0;
    margin-bottom: 0;
    position: fixed;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    background-color: white;
    margin-right: 1.2rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }

  .action-button {
    margin-right: 1rem;
  }

  .displayRcu{
   width:28rem;
  }

  .search-field{
    width: 40vw;
  }
  .iconExpend {
    float: right;
  }
  .title{
    display: flex;
    justify-content: space-around;
  }
.fields-group-title {
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 1rem;

  color: $app-blue;
}
</style>
